.float-box {
  position: absolute;
  z-index: 0;
  height: 100px;
  width: 100px;
  border-radius: 10px;
  transform: rotate(45deg);
  background-color: rgba(116, 207, 235, 0.2);
  will-change: transform;
}

.float-box:nth-child(1) {
  background-color: rgba(116, 207, 235, 0.2);
  top: 50px;
  right: 50px;
}

.float-box:nth-child(5) {
  background-color: rgba(116, 207, 235, 0.2);
  top: 50px;
  right: 50px;
}

.float-box:nth-child(2) {
  background-color: rgba(191, 81, 94, 0.2);
  mix-blend-mode: normal;
  top: 200px;
  right: 50px;
}

.float-box:nth-child(6) {
  background-color: rgba(191, 81, 94, 0.2);
  mix-blend-mode: normal;
  top: 200px;
  right: 50px;
}

.float-box:nth-child(3) {
  background-color: rgba(114, 201, 181, 0.2);
  top: 125px;
  right: 150px;
}

.float-box:nth-child(4) {
  background-color: rgba(114, 201, 181, 0.2);
  top: 125px;
  right: 150px;
}