.left-wing {
    position: absolute;
    z-index: 0;
    width: 360px;
    height: auto;
    top: -360px;
    left: 0px;
}

.right-wing {
    position: absolute;
    z-index: 0;
    width: 220px;
    height: auto;
    bottom: -20px;
    right: 0px;
}