@import "../styles/global.scss";

.PropositionSection {
    margin: 3rem !important;
    margin-top: 6rem !important;
    display: flex !important;

    .middle {
        position: relative !important;
        padding-top: 6rem;

    }

    .fixed-figure {
        position: relative;
        height: 33rem;
        width: 30rem;
    }

    &__image {
        position: relative;
        margin-top: -10rem;
        margin-bottom: -12rem;
        width: 25rem;
        height: auto;
        clip-path: circle(12.5rem at center);
    }

    .image-circle {
        position: relative;
        display: block;
        height: 9rem;
        width: 9rem;
        border-radius: 50%;
        // background-color: #74CFEB; // #72c9b5; #C15260;

        .circle-line {
            position: absolute;
            background-color: white;
            height: 2px;
        }

        .circle-line:nth-child(1) {
            width: 135px;
            top: 120px;
        }

        .circle-line:nth-child(2) {
            width: 143px;
            top: 125px;
        }

        .circle-line:nth-child(3) {
            width: 140px;
            top: 130px;
        }

        .circle-line:nth-child(4) {
            width: 145px;
            top: 135px;
        }
    }

}

.shadow-title {
    display: block;
    position: absolute;
    z-index: -1;
    // top: 4rem;
    bottom: -1.5rem;
    width: 100%;
    line-height: 200px;
    font-weight: bold;
    opacity: 0.5;
    overflow: visible;
    white-space: nowrap;
    color: transparent;
    -webkit-text-stroke: 1px #72c9b5;
    -webkit-touch-callout: none;
    -ms-user-select: none;
    user-select: none;
}

@media (max-width: $tablet) {
    .PropositionSection {
        flex-direction: column !important;
    }
}

@media (max-width: $mobile) {
    .PropositionSection {
        margin: 0rem !important;

        &__image {
            align-self: center;
            margin-top: -8rem;
            margin-bottom: 2rem;
            margin-left: -2.5rem;
            width: 20rem;
            clip-path: circle(10rem at center);
        }

        .image-circle {
            margin-top: -10rem;
        }
    }
}