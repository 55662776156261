@import "../styles/global.scss";

.FeaturesSection {
  padding: 5rem;
  position: relative;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #eaf6fc !important;
}

@media (max-width: $mobile) {
  .FeaturesSection {
    height: 140vh;
    padding: 2rem;
  }
}