@import "../styles/global.scss";

.RequestFormSectionContainer {
  position: relative;
  background-color: #F8F8FA;
  z-index: 1;
}

.RequestFormSection {
  &__container {
    max-width: 850px;
    ;
  }
}

@media(max-width: $tablet) {
  .RequestFormSectionContainer {
    padding: 0rem 3rem !important;
  }
}