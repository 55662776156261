@import "../styles/global.scss";

.FooterSection {
  position: relative;
  background-color: $primary;

  .styled-section {
    padding: 42px 0px !important;
  }

  &__container {
    padding: 0px 36px;
  }

  &__second-column {
    padding-left: 48px;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: white;
    }

  }

  &__Links {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 36px;
    color: white;
  }

  &__third-column {
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: white;
    }
  }
}

.hr {
  position: relative;
  margin-left: 16rem;
  width: 70%;
  height: 0.25px;
}

.copyright {
  color: white;
  display: flex;
  flex-direction: row;
  padding-left: 20vw;
}

.socials {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 7vw;

  img {
    margin-left: 18px;
    width: 24px !important;
    height: auto !important;
  }
}

@media(max-width: $tablet) {
  .FooterSection {
    margin-left: 1rem;
    margin-right: 1rem;

    &__second-column {
      width: 50%;
      margin-right: 0px !important;
      padding-left: 1rem;
    }

    &__third-column {
      width: 50%;
      margin-right: 0px !important;
      padding-left: 1rem;
    }

    .hr {
      position: relative;
      margin-left: 0rem;
      width: 100% !important;
      height: 0.25px;
    }

    .copyright {
      text-align: center;
      padding-left: 3rem;
      justify-content: center;
    }

    .socials {
      justify-content: center;
    }
  }
}