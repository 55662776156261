@import "../styles/global.scss";

.s-card {
    max-height: 300px;
    width: 12rem !important;
    border-radius: 6px;
    border: 1px solid #f0f2f3;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 2rem 2rem;
    margin: 1rem;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.02);
    background: white;
    position: relative;
    z-index: 2;
    text-align: center;

    h3 {
        position: relative;
        vertical-align: middle;
        padding-top: 1rem;
        font-size: 1.2em;
    }
}