@import "../styles/global.scss";

.ValuesSection {
    &__values {
        position: relative;
        display: flex;
        flex-direction: row;
    }
}

@media (max-width: $mobile) {
    .ValuesSection {

        &__values {
            margin: auto 0;
            flex-direction: column;
            align-items: center;
        }
    }
}