@import "../styles/global.scss";

.styled-border {
    border: 1px solid #ffffff;
    background-color: $primary;
    max-width: 150% !important;
    margin: 0rem 0rem;
}

.styled-input {
    height: 60px !important;
    background-color: transparent !important;
    border: none !important;
    color: white !important;
}

.styled-input::placeholder {
    color: white !important;
    font-size: 14px !important;
    vertical-align: middle !important;
}

.styled-button button {
    height: 60px !important;
    width: 60px !important;
    font-size: 24px !important;
    background-color: transparent;
    border: none !important;
    color: white;
}

@media(max-width: $tablet) {
    .styled-border {
        width: 98% !important;
    }

}