@import "../styles/global.scss";

.Features {
  display: flex;
  flex-direction: row;

  &__card {
    overflow: hidden;
    height: 40vh;
    border-radius: 6px;
    border: 1px solid #f0f2f3;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-positive: 1;
    flex-grow: 2;
    padding: 2rem 2rem;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.02);
    background: white;
    position: relative;
    z-index: 2;
    text-align: left;
  }

  &__title {
    text-align: left;
    margin-bottom: 1.2rem !important;
  }

  &__content {
    padding: 2rem 0rem !important;
  }

  &__image {
    width: 50px;
    margin: 30px auto;
  }
}

@media (max-width: $tablet) {
  .Features {
    &__card {
      max-height: 26vh;
    }
  }
}

@media (max-width: $mobile) {
  .Features {
    &__card {
      max-height: 35vh;
    }
  }
}