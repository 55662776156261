.SectionHeader {

  // Add bottom margin if element below
  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  &__no-classname {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    max-width: 700px;
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: inline-block;
  }

  .underline {
    position: relative;
    display: block;
    width: 10rem;
    height: 3px;
    background-color: #C15260;
    transition: width 0.5s;
  }

}

.SectionHeader:hover {
  .underline {
    width: 15rem;
  }
}

.be-white>h1,
.be-white>p {
  color: white !important;
}